<template>
  <v-card outlined>
    <v-card-title class="justify-center primary--text text-bold">Payments</v-card-title>
    <v-card-text class="mt-4">
      <p>Verify your profile to receive payments via TicketText.</p>
      <p>We pay out directly to your bank account thanks to our integration with <a class="blue--text lighten-4" target="_blank" href="https://stripe.com">Stripe</a>, a trusted and secure platform that handles billions of transactions around the world. When a customer books your event, we hold their money until the event happens. Once the event has passed, we automatically transfer you the funds, minus our fees.</p>
      <v-card-title class="px-0 primary--text">Stripe verification information</v-card-title>
      <p>Stripe is a regulated payment provider and is required to collect various documents in order to process payments. These compliance rules are regularly updated so you may need to provide more information in the future. We will notify you here and by email if that is ever the case.</p>
      <p>If you ever want to view or update the information you have provided to Stripe, simply press "Update KYC Info" below.</p>
      <p class="success--text" v-if="isKYCCompleted && !loading">Stripe account details configured successfully!</p>
      <p class="primary--text mt-n2" v-if="isKYCPending && !loading">Thanks for adding those details! Finally, we just need some proof of ID to complete the Stripe set up. Please click the link below and follow the on screen instructions.</p>
      <v-card-actions>
        <v-btn width="150" color="warning" @click="checkStripeKYC()">Update KYC Info</v-btn>
      </v-card-actions>
      <v-card-title class="px-0 primary--text">Bank account details
      </v-card-title>
      <p class="success--text" v-if="bankAccounts">Bank account details configured successfully!</p>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              v-on:keypress="isNumber($event)"
              dense
              outlined
              clearable
              v-model="data.accountNumber"
              :rules="[v => !!v || 'Account Number is required']"
              name="instagram"
              label="Account Number"
              id="instagram"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.routing_number"
              :rules="[
                v => !!v || 'Sort Code is required',
                v => /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(v) || 'Invalid Sort Code',
              ]"
              name="twitter"
              label="Sort Code"
              id="twitter"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              clearable
              outlined
              v-model="data.account_holder_name"
              :rules="[v => !!v || 'Account Holder Name is required']"
              name="facebook"
              label="Account Holder Name"
              id="facebook"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              clearable
              outlined
              v-model="data.bank_name"
              :rules="[v => !!v || 'Bank Name is required']"
              name="youtube"
              label="Bank Name"
              id="youtube"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <v-btn width="200" :loading="loading" color="primary" @click="save()" v-if="!showAddBank">Add Bank Details</v-btn>
        <v-btn width="200" :loading="loading" color="primary" @click="save()" v-if="showAddBank">Edit Bank Details</v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { UserService, PaymentService } from '@services';

export default {
  data() {
    return {
      data: {},
      loading: false,
      showStripePendingNotification: false,
      showAddBank: false,
      bankAccounts: 0,
      deleteLoading: false,
      bankDetail: {},
      isKYCPending: false,
      isKYCCompleted: false,
    };
  },
  created: async function() {
    this.checkPendingStripeKYC();
    this.getOrganiserData();
  },
  async mounted() {
    if (!window.Stripe) {
      await this.$loadScript('https://js.stripe.com/v3/');
    }
  },
  methods: {
    async getOrganiserData() {
      const organisationData = await UserService.checkpayment(this.$user.organiser);
      if (organisationData) {
        this.data = organisationData;
        this.data.account = organisationData.account ? true : false;
        this.data.accountNumber = organisationData.last4;
        this.data.routing_number = organisationData.routing_number.replace(/-/g, "");
        // this.data.accountNumber = new Array(accountNumber.length + 1).join('*') + accountNumber;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to add/update payout details?`,
          resolve: async () => {
            this.loading = true;
            const bank_account = {
              country: 'GB',
              currency: 'GBP',
              account_number: this.data.accountNumber,
              routing_number: this.data.routing_number,
              account_holder_name: this.data.account_holder_name,
              account_holder_type: 'individual',
              bank_name: this.data.bank_name,
            };

            const stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_KEY);
            const bankAccountResult = await stripe.createToken('bank_account', bank_account);
            console.log('🚀 ~ file: payout.vue ~ line 131 ~ resolve: ~ bankAccountResult', bankAccountResult);
            if (bankAccountResult && bankAccountResult.error) {
              this.$error(bankAccountResult.error.message);
              this.loading = false;
            } else {
              if (bankAccountResult && bankAccountResult.token.id) {
                await UserService.savePayout({
                  external_account: bankAccountResult.token.id,
                });
              }
              this.bankAccounts = 1;
              this.getOrganiserData();
              this.loading = false;
              this.showAddBank = true;
            }
          },
        });
      }
    },
    cancel() {
      this.$next({ name: 'promoter.account.view' });
    },
    async checkStripeKYC() {
      let user = await UserService.me();
      if (user && user.role === 'promoter' && user.stripe && user.stripe.accountId) {
        const striperesponse = await PaymentService.getAccountInfo(user.stripe.accountId);
        const stripeLinkresponse = await PaymentService.getStripeLink(user.stripe.accountId);
        this.showAddBank = striperesponse.data.external_accounts.data.length;
        if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "verified") {
          this.isKYCPending = false;
        } 
        window.open(stripeLinkresponse.url)
      }
    },
    async checkPendingStripeKYC() {
      this.loading = true;
      let user = await UserService.me();
      if (user && user.role === 'promoter' && user.stripe && user.stripe.accountId) {
        const striperesponse = await PaymentService.getAccountInfo(user.stripe.accountId);
        this.showAddBank = striperesponse.data.external_accounts.data.length > 0 ? true : false;
        this.bankDetail = striperesponse.data.external_accounts.data.length > 0 ? striperesponse.data.external_accounts.data[0] : {};
        this.bankAccounts = striperesponse.data.external_accounts.data.length;
        if(!striperesponse.data.payouts_enabled) {
          this.showStripePendingNotification = true;
        }
        if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "unverified" && striperesponse.data.legal_entity.dob.day !== null) {
          this.isKYCPending = true;
        } else if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "verified") {
          this.isKYCCompleted = true;
        } else if(striperesponse.data && striperesponse.data.legal_entity && striperesponse.data.legal_entity.verification && striperesponse.data.legal_entity.verification.status === "pending") {
          this.isKYCPending = true;
        }
        this.loading = false;
      }
    }
  },
};
</script>