<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card outlined>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.socialProfiles.instagram"
              name="instagram"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              label="Instagram"
              id="instagram"
              prepend-inner-icon="mdi-instagram"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.socialProfiles.twitter"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              name="twitter"
              label="Twitter"
              id="twitter"
              prepend-inner-icon="mdi-twitter"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.socialProfiles.facebook"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              name="facebook"
              label="Facebook"
              id="facebook"
              prepend-inner-icon="mdi-facebook"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.socialProfiles.youtube"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              name="youtube"
              label="YouTube"
              id="youtube"
              prepend-inner-icon="mdi-youtube"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              v-model="data.socialProfiles.pinterest"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              name="pinterest"
              label="Pinterest"
              id="pinterest"
              prepend-inner-icon="mdi-pinterest"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              clearable
              outlined
              v-model="data.socialProfiles.linkedin"
              :rules="[
                v =>
                  !v ||
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                    v
                  ) ||
                  'Invalid URL',
              ]"
              name="linkedin"
              label="LinkedIn"
              id="linkedin"
              prepend-inner-icon="mdi-linkedin"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="cancel()">Cancel</v-btn>
        <v-btn width="100" color="primary" :loading="loading" @click="save()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { UserService } from '@services';
export default {
  data() {
    return {
      valid: false,
      data: {
        socialProfiles: {
          facebook: '',
          twitter: '',
          youtube: '',
          instagram: '',
          pinterest: '',
          linkedin: '',
        },
      },
      loading: false,
    };
  },
  async created() {
    this.getOrganiserData();
  },
  methods: {
    async getOrganiserData() {
      const organisationData = await UserService.getOrganiserInfo(this.$user.organiser);
      if (!organisationData.socialProfiles) {
        organisationData.socialProfiles = {};
      }
      this.data = organisationData;
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to add/update social links?`,
          resolve: async () => {
            this.loading = true;
            await UserService.editinfo(this.data._id, this.data);
            this.loading = false;
          },
        });
      }
    },
    cancel() {
      this.$next({ name: 'promoter.account.view' });
    },
  },
};
</script>
