<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="primary--text">
            Account
            <v-spacer />
            <v-btn color="primary" :to="{ name: 'promoter.account.edit', params: { tab: 0 } }">Edit</v-btn>
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="80">
                  <v-img v-if="user.image" :src="$getProfile200Image(user._id, user.image)" />
                  <v-img v-if="!user.image" src="@/assets/images/promotor.svg" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-if="user.firstName">
                    {{ `${user.firstName} ${user.lastName}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ user.role | capitalize }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list dense>
              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">First name</div>
                  <div>{{ user.firstName }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Last name</div>
                  <div>{{ user.lastName }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Email</div>
                  <div>{{ user.email }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Phone</div>
                  <div>{{ user.phone }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Address</div>
                  <div>{{ user.address }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">City</div>
                  <div>{{ user.city }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">State/County</div>
                  <div>{{ user.state }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Postcode</div>
                  <div>{{ user.postcode }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Country</div>
                  <div>{{ user.country }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title class="primary--text">
            Payout
            <v-spacer />
            <v-btn color="primary" :to="{ name: 'promoter.account.edit', params: { tab: 1 } }">Edit</v-btn>
          </v-card-title>

          <v-card-text>
            <v-list dense>
              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Account number</div>
                  <div>{{ payouts.accountNumber }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Sort code</div>
                  <div>{{ payouts.routing_number }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Account holder name</div>
                  <div>{{ payouts.account_holder_name }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Bank name</div>
                  <div>{{ payouts.bank_name }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <br />

        <v-card>
          <v-card-title class="primary--text">
            Social Media
            <v-spacer />
            <v-btn color="primary" :to="{ name: 'promoter.account.edit', params: { tab: 2 } }">Edit</v-btn>
          </v-card-title>

          <v-card-text>
            <v-list dense>
              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Instagram</div>
                  <div>{{ socialProfiles.instagram }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Twitter</div>
                  <div>{{ socialProfiles.twitter }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Facebook</div>
                  <div>{{ socialProfiles.facebook }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">YouTube</div>
                  <div>{{ socialProfiles.youtube }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Pinterest</div>
                  <div>{{ socialProfiles.pinterest }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">LinkedIn</div>
                  <div>{{ socialProfiles.linkedin }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { UserService, PaymentService } from '@services';

export default {
  data() {
    return {
      user: {},
      socialProfiles: {},
      payouts: {},
    };
  },
  created() {
    this.getCurrentUser();
    this.getOrganiserData();
    this.getPayoutData();
    this.checkStripeKYC();
  },
  methods: {
    async getCurrentUser() {
      this.user = await UserService.me();
    },
    async getOrganiserData() {
      const response = await UserService.getOrganiserInfo(this.$user.organiser);
      if (response) {
        this.socialProfiles = response.socialProfiles || {};
      }
    },
    async getPayoutData() {
      const organisationData = await UserService.checkpayment(this.$user.organiser);
      if (organisationData) {
        this.payouts = organisationData;
        const accountNumber = organisationData.last4;
        this.payouts.accountNumber = new Array(accountNumber.length + 1).join('*') + accountNumber;
      }
    },
    async checkStripeKYC() {
      let user = await UserService.me();
      if (user && user.role === 'promoter' && user.stripe && user.stripe.accountId) {
        const striperesponse = await PaymentService.getAccountInfo(user.stripe.accountId);
        const stripeLinkresponse = await PaymentService.getStripeLink(user.stripe.accountId);
        this.showKYCNotification(striperesponse, stripeLinkresponse);
      }
    }
  },
};
</script>
