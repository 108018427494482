<template>
  <div>
    <v-card>
      <v-card-text>
        <v-tabs class="mb-0 mb-sm-10" v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Account</v-tab>
          <v-tab>Payments</v-tab>
          <v-tab>Social media</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Account />
          </v-tab-item>

          <v-tab-item>
            <Payments />
          </v-tab-item>

          <v-tab-item>
            <SocialMedia />
          </v-tab-item>

        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SocialMedia from './social-media';
import Account from './account/index';
import Payments from './payments';

export default {
  components: {
    Account,
    SocialMedia,
    Payments,
  },
  data: () => ({
    tab: 0,
  }),
  created() {
    this.tab = parseInt(this.$route.params.tab) || 0;
  },
};
</script>
