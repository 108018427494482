var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","name":"instagram","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"label":"Instagram","id":"instagram","prepend-inner-icon":"mdi-instagram"},model:{value:(_vm.data.socialProfiles.instagram),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "instagram", $$v)},expression:"data.socialProfiles.instagram"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"name":"twitter","label":"Twitter","id":"twitter","prepend-inner-icon":"mdi-twitter"},model:{value:(_vm.data.socialProfiles.twitter),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "twitter", $$v)},expression:"data.socialProfiles.twitter"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"name":"facebook","label":"Facebook","id":"facebook","prepend-inner-icon":"mdi-facebook"},model:{value:(_vm.data.socialProfiles.facebook),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "facebook", $$v)},expression:"data.socialProfiles.facebook"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"name":"youtube","label":"YouTube","id":"youtube","prepend-inner-icon":"mdi-youtube"},model:{value:(_vm.data.socialProfiles.youtube),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "youtube", $$v)},expression:"data.socialProfiles.youtube"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","clearable":"","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"name":"pinterest","label":"Pinterest","id":"pinterest","prepend-inner-icon":"mdi-pinterest"},model:{value:(_vm.data.socialProfiles.pinterest),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "pinterest", $$v)},expression:"data.socialProfiles.pinterest"}})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","outlined":"","rules":[
              v =>
                !v ||
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(
                  v
                ) ||
                'Invalid URL',
            ],"name":"linkedin","label":"LinkedIn","id":"linkedin","prepend-inner-icon":"mdi-linkedin"},model:{value:(_vm.data.socialProfiles.linkedin),callback:function ($$v) {_vm.$set(_vm.data.socialProfiles, "linkedin", $$v)},expression:"data.socialProfiles.linkedin"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"width":"100","outlined":"","color":"primary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"width":"100","color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }