<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card outlined>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              name="name"
              :rules="[v => !!v || 'Name is required']"
              label="Name"
              v-model="data.name"
              clearable
              :error-messages="nameError"
              @input="nameError = []"
              @change="onChangeName()"
              id="name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              name="slug"
              clearable
              :rules="[v => !!v || 'Slug is required']"
              label="Slug"
              @input="slugError = []"
              @change="onChangeSlug()"
              :error-messages="slugError"
              v-model="data.slug"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-autocomplete
              v-model="data.venue"
              :items="venues"
              clearable
              :item-text="'name'"
              :item-value="'_id'"
              label="Venue"
              dense
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-select
              v-model="data.type"
              :items="types"
              clearable
              :item-text="'label'"
              :rules="[v => !!v || 'Type is required']"
              :item-value="'value'"
              label="Type"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              name="name"
              clearable
              :disabled="true"
              label="Event Approval"
              v-bind:value="data.isStatusVetting ? 'Yes' : 'No'"
              id="name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="slug"
              :disabled="true"
              label="Refund ticket"
              v-bind:value="data.isAbleToRefundTicket ? 'Yes' : 'No'"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn width="100" outlined color="primary" @click="cancel()">Cancel</v-btn>
        <v-btn width="100" color="primary" :loading="loading" @click="save()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { UserService, VenueService, PromoterService } from '@services';
export default {
  data() {
    return {
      valid: false,
      loading: false,
      types: [
        {
          label: 'Promoter',
          value: 'promoter',
        },
        {
          label: 'Artist',
          value: 'artist',
        },
        {
          label: 'Venue',
          value: 'venue',
        },
      ],
      venues: [],
      slugError: [],
      nameError: [],
      data: {
        _id: '',
        name: '',
        slug: '',
        venue: '',
        type: '',
        isStatusVetting: true,
        isAbleToRefundTicket: false,
        staus: '',
      },
    };
  },
  created: async function() {
    this.getOrganiserData();
    this.venues = await VenueService.get({ status: 'active', limit: 'all' });
  },
  methods: {
    async getOrganiserData() {
      this.data = await UserService.getOrganiserInfo(this.$user.organiser);
    },
    async save() {
      if (this.$refs.form.validate() && !this.slugError.length && !this.nameError.length) {
        this.$confirm({
          title: `Are you sure you want to update document?`,
          resolve: async () => {
            this.loading = true;
            await UserService.editinfo(this.data._id, this.data);
            // this.$refs.form.reset();
            this.loading = false;
          },
        });
      }
    },
    cancel() {
      this.$next({ name: 'promoter.account.view' });
    },
    async onChangeName() {
      this.nameError = [];
      if (this.data.name) {
        const response = await PromoterService.checkDuplicate(this.data.name);
        if (response) {
          if (response._id != this.data._id) {
            this.nameError = ['Name already exists'];
          }
        }
      }
    },
    async onChangeSlug() {
      this.slugError = [];
      if (this.data.slug) {
        const response = await PromoterService.checkDuplicateSlug(this.data.slug);
        if (response) {
          if (response._id != this.data._id) {
            this.slugError = ['Slug already exists'];
          }
        }
      }
    },
  },
};
</script>
