<template>
  <v-card outlined>
    <v-card-text>
      <v-list-item class="pl-0">
        <v-list-item-avatar size="80">
          <v-img v-if="data.image" :src="$getProfile200Image(data._id, data.image)" />
          <v-img v-if="!data.image" src="@/assets/images/promotor.svg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="mb-2">{{ data.firstName }} {{ data.lastName }}</v-list-item-title>
          <v-list-item-subtitle>
            <input type="file" style="display: none" id="fileInput" @change="uploadFile" @click="clickFile" />
            <v-btn
              small
              color="primary"
              :loading="loading.upload"
              onclick="document.getElementById('fileInput').click()"
              >Upload</v-btn
            >
            <v-btn small class="ml-2" :loading="loading.remove" @click="removeImage()" v-if="this.data.image"
              >Remove</v-btn
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="firstName"
              label="First Name"
              :rules="[
                v => !!v || 'First Name is required',
                v => (v && v.length < 20) || 'First name must less than 20 character',
              ]"
              v-model="data.firstName"
              id="firstName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="lastName"
              label="Last Name"
              :rules="[
                v => !!v || 'Last Name is required',
                v => (v && v.length < 20) || 'Last name must less than 20 character',
              ]"
              v-model="data.lastName"
              id="lastName"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="email"
              label="email"
              v-model="data.email"
              :disabled="true"
              id="email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              v-on:keypress="isNumber($event)"
              outlined
              clearable
              name="name"
              :rules="[v => !!v || 'Phone is required', v => (v && v.length == 11) || 'Phone number must 11 digit']"
              label="Phone"
              v-model="data.phone"
              id="phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="name"
              :rules="[v => !!v || 'Address is required']"
              label="Address"
              v-model="data.address"
              id="address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="name"
              :rules="[v => !!v || 'City is required']"
              label="City"
              v-model="data.city"
              id="city"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="name"
              :rules="[v => !!v || 'State/County is required']"
              label="State/County"
              v-model="data.state"
              id="state"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="name"
              label="Postcode"
              :rules="[v => !!v || 'Postcode is required']"
              v-model="data.postcode"
              id="postcode"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" class="py-2">
            <v-text-field
              dense
              outlined
              clearable
              name="name"
              :rules="[v => !!v || 'Country is required']"
              label="Country"
              v-model="data.country"
              v-on:keypress="isLetter($event)"
              id="country"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn width="100" outlined color="primary" @click="cancel()">Cancel</v-btn>
      <v-btn width="100" color="primary" :loading="loading.save" @click="save()">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { UserService, MediaService } from '@services';
export default {
  data() {
    return {
      valid: false,
      data: {},
      image: '',
      loading: {
        upload: false,
        save: false,
        remove: false,
      },
    };
  },
  created() {
    this.getCurrentUser();
  },
  methods: {
    disableRemove() {
      if (this.data.image !== '') return false;
      else return true;
    },
    acceptNumber() {
      var replacedInput = this.data.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.data.phone = !replacedInput[2]
        ? replacedInput[1]
        : '(' + replacedInput[1] + ') ' + replacedInput[2] + (replacedInput[3] ? '-' + replacedInput[3] : '');
    },
    async getCurrentUser() {
      this.data = await UserService.me();
      this.$store.set('auth/user', this.data);
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: `Are you sure you want to ${this.data._id ? 'update' : 'create'} this document?`,
          resolve: async () => {
            this.loading.save = true;
            await UserService.update(this.data._id, this.data);
            this.loading.save = false;
          },
        });
      }
    },
    clickFile(event) {
      event.target.value = '';
    },
    async uploadFile(event) {
      if (event.target.files && event.target.files.length) {
        this.loading.upload = true;
        const key = Date.now()
        await MediaService.uploadUserimage(key, { file: event.target.files[0] });
        // await UserService.update(this.data._id, { image: event.target.files[0].name });
        // this.data.image = event.target.files[0].name;
        this.loading.upload = false;
        this.getCurrentUser();
      }
    },
    async removeImage() {
      this.$confirm({
        title: `Are you sure you want to delete this image?`,
        resolve: async () => {
          this.loading.remove = true;
          await MediaService.removeUserimage(this.data.image)
          // await UserService.update(this.data._id, { image: '' });
          this.data.image = '';
          this.loading.remove = false;
          this.getCurrentUser();
        },
      });
    },
    cancel() {
      this.$next({ name: 'promoter.account.view' });
    },
  },
};
</script>
